@import "styles/variables-colors";

.page {
  display: flex;
  background-color: $bgColor;
  height: 100%;
  overflow: auto;

  &.reportPage {
    background: white;
  }
}

.nav {
  position: absolute;
  z-index: 2;
  height: 100%;
  background-color: rgba(253, 250, 243, 0.5);
  backdrop-filter: blur(8px);
}

.main {
  width: 100%;
  padding: 0 40px 0 112px;
  overflow: scroll;
}

@media (width <= 810px) {
  .nav {
    display: none;
  }

  .main {
    padding: 0;
  }
}