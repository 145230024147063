$periwinkle-color: #f6f1ff;
$wonder-color: #baa9e9;
$ink-color: #2a2448;
$heading-color: #322F3C;
$amythyst-color: #856ecb;
$wildberry-color: #623ba5;
$bgColor: #FDFAF3;
$selected: #f9f0db;
$colorBorder: #E9E9F0;
$colorBgHeaderDefault: #F5F5F9;
$colorBgTonalOutlineDefault: #FDFAF3;
$colorCellBgDefault: #FFF;
$colorCellBgHover: #F5F5F9;
$colorInputError: #983E3E;
$colorPrimary: #7D52CB;
$colorTagDefaultBg: #E9E9F0;
$colorTextDescription: #79739D;
$colorTextDisabled: #E9E9F0;
$colorTextLabel: #5E5776;
$colorTextPlaceholder: #BDBDD3;
$lightPrimary: #F5F5F9;
$buttonHover: #F7ECD4;
$textDefault: #322F3C;
/* TODO Replace usage with $colorTextDescription */
$textDescription: #79739D;
$textDisabled: #BDBDD3;
$navItemColor: #8784AD;
$colorPrimaryBorderHover: #B398E8;
$incompleteEmployee: #e4f2f7;
$colorTableInputHover: #F0ECFB;
$colorInputBorder: #7D52CB;

//NEW COLORS
$graphite0: #f5f5f9;
$graphite1: #e9e9f0;
$graphite6: #79739d;
$graphite8: #5e5776;
$wildberry3: #D0C1F1;
$wildberry6: #7D52CB;
